import React, {useState} from "react"
import Milestone from "../interfaces/milestone.interface"
import {Trans} from "gatsby-plugin-react-i18next"

type MilestoneProps = {
  milestones: Array<Milestone>
}

const MilestoneMenu: React.FC<MilestoneProps> = ({
  milestones,
}: MilestoneProps) => {
  const isBrowser = () => typeof window !== "undefined"
  const [isActive, setActive] = useState(false)

  const openMenu = () => {
    setActive(true)
  }

  const closeMenu = () => {
    setActive(false)
  }

  const onButtonClicked = (event: React.MouseEvent) => {
    ;(event.currentTarget as any).focus()
  }

  const onMilestoneClicked = (milestone: Milestone) => {
    if (isBrowser()) {
      window.location.href = "#" + milestone.id
    }
  }

  return (
    <div className="relative inline-block text-left z-50 shadow-xl shadow-white">
      <div className="z-30">
        <button
          type="button"
          className="border border-gray-300 bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-md md:text-lg font-medium text-gray-500 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500"
          id="milestone-menu"
          onFocus={openMenu}
          onBlur={closeMenu}
          onClick={onButtonClicked}
        >
          <Trans>selectAMilestone</Trans>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
          </svg>
        </button>
      </div>

      <div
        className={
          `transition z-20 ` +
          (isActive ? "skew-0 skale-1" : "skew-y-12 scale-0")
        }
        onMouseDown={event => event.preventDefault()}
      >
        <div className="origin-top-right absolute right-0 mt-2 w-full md:w-auto rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
          <div
            className="py-1 "
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="milestone-menu"
          >
            {milestones.map(milestone => (
              <button
                key={milestone.id}
                onClick={() => onMilestoneClicked(milestone)}
                className="block w-full text-left px-4 py-2 text-md md:text-lg text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>{milestone.name}</span>
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MilestoneMenu
