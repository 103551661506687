import {GatsbyImage} from "gatsby-plugin-image"
import React, {FC, ReactElement, useState} from "react"
import Milestone from "../interfaces/milestone.interface"
import MilestoneContent from "./milestone-content"

type MilestoneProps = {
  milestone: Milestone
}

const MilestoneSection: FC<MilestoneProps> = ({
  milestone,
}: MilestoneProps): ReactElement => {
  const [isScrollStopActive, setScrollStopActive] = useState(true)

  const sectionTeaserStyle = {
    height: "95vh",
  }

  return (
    <section
      id={`${milestone.id}`}
      className={isScrollStopActive ? "snap-end" : ""}
    >
      <div className="w-screen relative">
        <GatsbyImage
          image={milestone.image?.localFile.childImageSharp.gatsbyImageData}
          alt={`Picture of ${milestone.name}`}
          className="absolute w-100 inset-x-0 top-0 z-0"
          style={{
            ...sectionTeaserStyle,
            position: "absolute",
          }}
        />
        <div
          style={sectionTeaserStyle}
          className="z-10 h-screen relative text-center text-white flex flex-col justify-end"
        >
          <div
            className={`bg-gradient-to-t from-${milestone.color}`}
            id={`${milestone.id}` + "_content"}
          >
            <div className="container mx-auto max-w-4xl">
              <h2 className="text-7xl md:text-9xl text-shadow-sm font-semibold">
                {milestone.year}
              </h2>
              <h3 className="text-2xl md:text-4xl text-shadow-sm">
                {milestone.name}
              </h3>
              <p className="p-6 md:p-14 text-lg">
                {milestone.shortDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`bg-${milestone.color} w-full text-white pb-1`}>
        <div className="container mx-auto max-w-4xl">
          <MilestoneContent
            milestone={milestone}
            setScrollStopActive={setScrollStopActive}
          />
        </div>
      </div>
    </section>
  )
}
export default MilestoneSection
