import React, {ReactElement} from "react"
import {HasChildren} from "../interfaces/HasChildren.interface"
import LanguageMenu from "./language-menu"

const defaultProps = {
  siteTitle: ``,
}

type HeaderProps = typeof defaultProps &
  HasChildren & { languageMenu?: React.ReactNode }

const Header = ({ children }: HeaderProps): ReactElement => (
  <header className="snap-start w-screen min-h-screen relative text-center flex bg-stage bg-fixed bg-cover bg-center">
    <div className="absolute w-full top-5">
      <LanguageMenu />
    </div>
    <div className="m-auto my-20 md:flex items-center">{children}</div>
  </header>
)

export default Header

Header.defaultProps = defaultProps
