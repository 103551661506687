import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {graphql, PageProps} from "gatsby"
import Milestone from "../interfaces/milestone.interface"
import Header from "../components/header"
import MilestoneMenu from "../components/milestone-menu"
import MilestoneSection from "../components/milestone-section"
import HeroInterface from "../interfaces/hero.interface"

import ReactMarkdown from "react-markdown"
import logo from "../images/allianz-logo.svg";

type IndexPageProps = PageProps<{
  strapiHomepage: {
    siteName: string
    stage: HeroInterface
  }
  allStrapiMilestone: {
    nodes: Milestone[]
  }
}>

const IndexPage = ({ data }: IndexPageProps) => {
  const { allStrapiMilestone, strapiHomepage } = data
  const milestones = allStrapiMilestone.nodes

  return (
    <Layout>
      <Seo title={strapiHomepage.siteName} />
      <Header>
        <div className="flex flex-col md:items-center gap-5 md:gap-14 max-w-3xl tracking-wide px-5 md:px-10 justify-between">
          <div>
            <img
              src={logo}
              alt="Allianz Logo"
              className="w-40 mb-12 m-auto"
            />
          </div>
          <h1 className="text-primary text-4xl md:text-6xl lg:text-8xl font-bold uppercase">
            {strapiHomepage.stage.title}
          </h1>
          <div className="text-xl md:text-4xl leading-normal font-light prose prose-pink">
            <ReactMarkdown
                           linkTarget={'_blank'}

            >
              {strapiHomepage.stage.text}
            </ReactMarkdown>
          </div>
          <MilestoneMenu milestones={milestones} />
        </div>
      </Header>

      {milestones.map(milestone => (
        <MilestoneSection milestone={milestone} key={milestone.id} />
      ))}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiHomepage(locale: { eq: $language }) {
      siteName
      stage {
        title
        text
      }
    }
    allStrapiMilestone(
      filter: { locale: { eq: $language } }
      sort: { fields: [year], order: ASC }
    ) {
      nodes {
        name
        id
        year
        locale
        shortDescription
        color
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        content {
          title
          elements {
            text
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`
