import {GatsbyImage} from "gatsby-plugin-image"
import React, {useState} from "react"
import ReactMarkdown from "react-markdown"
import Milestone from "../interfaces/milestone.interface"
import {Trans} from "gatsby-plugin-react-i18next"

type MilestoneContentProps = {
  milestone: Milestone
  setScrollStopActive(active: boolean): void
}
const MilestoneContent = ({
  milestone,
  setScrollStopActive,
}: MilestoneContentProps) => {
  const isBrowser = () => typeof window !== "undefined"
  const [isInitialized, setInitialized] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const contentHash = `#${milestone.id}` + "_content"

  const handleOpen = (isOpen: boolean) => {
    setOpen(isOpen)
    setScrollStopActive(!isOpen)
  }

  if (!isInitialized && isBrowser() && window?.location?.hash === contentHash) {
    handleOpen(true)
    setInitialized(true)
  }

  const toggleContent = () => {
    handleOpen(!isOpen)

    if (!isOpen) {
      if (isBrowser()) {
        window.location.href = contentHash
      }
      setInitialized(true)
    }
  }

  return (
    <div className="text-center ">
      <button className="uppercase mb-8" onClick={toggleContent}>
        {isOpen ? <Trans>readLess</Trans> : <Trans>readMore</Trans>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={
            "h-5 w-5 ml-2 align-middle inline-block transition-all " +
            (isOpen ? "transform rotate-180" : "")
          }
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      <div
        className={
          "overflow-hidden transition-all transform " +
          (isOpen
            ? "opacity-100 scale-100 h-full"
            : "scale-50 -translate-y-1/2 opacity-0 h-0")
        }
      >
        <div className="bg-white mb-14 text-left p-6 md:p-14 text-black prose lg:prose-xl max-w-none">
          <div className={`text-${milestone.color}`}>
            <h3>{milestone.content?.title}</h3>
          </div>
          {milestone.content?.elements.map((element, index) => (
            <div key={index}>
              {element.text ? <ReactMarkdown children={element.text} /> : ""}

              {element.image ? (
                <GatsbyImage
                  image={
                    element.image?.localFile?.childImageSharp?.gatsbyImageData
                  }
                  alt={`Picture of ${milestone.content?.title}`}
                />
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default MilestoneContent
